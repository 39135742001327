//
// ===== File global_constant.ts
//
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import * as io from 'socket.io-client';
// 'use strict';
// declare var io: any;

@Injectable()
export class Globalconstant {

  api_token: any;
  apiUrl: any;
  logoBaseUrl: any;
  public imagepath: any;
  imageUrl: any;
  uploadUrl: any;
  pinnerUrl: any;
  doerUrl: any;
  nodeApiUrl: any;
  apiChatUrl: any;
  CHAT_URL: any;
  userData = {};
  frontend_url: any;
  chatFileUrl: any;
  public notificationSocket;
  paginateCount = 9;
  globalSocketCall1 = 0;
  baseUrl: any = '';
  communityUrl: any = '';
  environment: any = environment;
  updateBoard = 0;
  styles: any;
  public letterArray: any = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  public blogCreateMessage = 'We received your blog and are reviewing it now. If accepted, it�ll be posted asap!';
  public updateDataBackAlertMsg = 'Are you sure you want to leave before saving these updates?';
  public deleteLocationConfirmationMsg = 'You will not be able to recover this address!';
  public deletePaymentConfirmationMsg = 'You will not be able to recover this';
  public deletePrimaryMsg = 'Please add a new primary payment method before deleting this one';
  public google_map_api = 'AIzaSyAU49KAe5f_bMOoWt60efXc3SD633JPvMo';
  public pinner_profile_complete_msg = 'Please fill in your basic profile information (name, address, & contact info) so you can start connecting with your contacts and community and create pins to get stuff done.';
  pinnerReviewLimit = 5;
  urlpattern = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  // emailPattern = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$';
  emailPattern = '^[A-Za-z0-9!#$%&\'*+-/=?^_`{|}~]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$';
  constructor() {
    this.baseUrl = this.environment.chatUrl;
    this.communityUrl = this.environment.communityUrl;
    this.apiUrl = this.environment.baseUrl;
    this.logoBaseUrl = '';
    this.imagepath = './assets/images/';
    this.chatFileUrl = window.location.protocol + '//' + window.location.hostname + '/pindo-server-node/uploads';
    this.styles =  [  {
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#f5f5f5'
      }
    ]
  },
  {
    'elementType': 'labels.icon',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#616161'
      }
    ]
  },
  {
    'elementType': 'labels.text.stroke',
    'stylers': [
      {
        'color': '#f5f5f5'
      }
    ]
  },
  {
    'featureType': 'administrative.land_parcel',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative.land_parcel',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#bdbdbd'
      }
    ]
  },
  {
    'featureType': 'administrative.neighborhood',
    'elementType': 'labels.text',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#eeeeee'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#757575'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#e5e5e5'
      }
    ]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#ffffff'
      }
    ]
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#757575'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#dadada'
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#616161'
      }
    ]
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road.local',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e'
      }
    ]
  },
  {
    'featureType': 'transit.line',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#e5e5e5'
      }
    ]
  },
  {
    'featureType': 'transit.station',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#eeeeee'
      }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'geometry',
    'stylers': [
      {
        'color': '#c9c9c9'
      }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'labels.text.fill',
    'stylers': [
      {
        'color': '#9e9e9e'
      }
    ]
  }
];

this.styles = JSON.stringify(this.styles);
    // DEV UIPL DETAILS
    // this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    // this.apiChatUrl = `${this.baseUrl}:3009/service`;
    // this.CHAT_URL = `${this.baseUrl}:3009`;
    // this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    // this.uploadUrl = this.baseUrl + '/pindo-server/uploads';

    // PRODUCTION PINDO DETAILS
    this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    this.apiChatUrl = `${this.baseUrl}:3009/service`;
    this.CHAT_URL = `${this.baseUrl}:3009`;
    this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    this.uploadUrl = this.baseUrl + '/pindo-server/uploads';

    //BETA PINDO
    /* this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    this.apiChatUrl = `${this.baseUrl}:3009/service`;
    this.CHAT_URL = `${this.baseUrl}:3009`;
    this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    this.uploadUrl = this.baseUrl + '/pindo-server/uploads'; */

    //BETA PINDO
    // this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    // this.apiChatUrl = `${this.baseUrl}:3008/service`;
    // this.CHAT_URL = `${this.baseUrl}:3008`;
    // this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    // this.uploadUrl = this.baseUrl + '/pindo-server/uploads';

    //DEV PINDO DETAILs
    // this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    // this.apiChatUrl = `${this.baseUrl}:3010/service`;
    // this.CHAT_URL = `${this.baseUrl}:3010`;
    // this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    // this.uploadUrl = this.baseUrl + '/pindo-server/uploads';

    //LOCAL PINDO DETAILS
    /*this.imageUrl = this.baseUrl + '/pindo-website/assets/images/';
    this.apiChatUrl = `${this.baseUrl}:3009/service`;
    this.CHAT_URL = `${this.baseUrl}:3009`;
    this.frontend_url = window.location.protocol + '//' + window.location.hostname;
    this.uploadUrl = 'http://dev.uiplonline.com/pindo-server/uploads'; */


    this.pinnerUrl = 'http://pinner.pindo.com';
    //Doer url after login
    // this.doerUrl = "http://doer.pindo.com";
    // this.uploadUrl = window.location.protocol+'//'+window.location.hostname+'/pindo-server/uploads';

    //if ("frontend_token" in localStorage) {
    this.notificationSocket = io.connect(this.CHAT_URL + '/textNotification');
    this.notificationSocket.emit('joinNotificationRoom', 'notificationRoom');
    this.notificationSocket.on('err', (res) => console.log(res));
    //}
  }
}
